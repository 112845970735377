<template>
  <div class="creat_bg" v-if="boxDetail.flag">
    <div class="animate__animated animate__fadeInDown" ref="slider">
      <div class="title">盒子包含</div>
      <div
        class="creat_close"
        @click="
          handleclose();
          playAudio();
        "
      >
        <svg
          t="1708678106448"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="2989"
        >
          <path
            d="M898.844444 830.577778c22.755556 17.066667 22.755556 51.2 0 73.955555s-51.2 22.755556-73.955555 0L512 591.644444l-312.888889 312.888889c-11.377778 11.377778-22.755556 17.066667-39.822222 17.066667-11.377778 0-28.444444-5.688889-39.822222-17.066667-22.755556-22.755556-22.755556-51.2 0-73.955555l312.888889-312.888889-307.2-318.577778c-11.377778-5.688889-17.066667-22.755556-17.066667-34.133333 0-11.377778 5.688889-28.444444 17.066667-34.133334 22.755556-22.755556 51.2-22.755556 73.955555 0L512 443.733333l312.888889-312.888889c22.755556-22.755556 51.2-22.755556 73.955555 0s22.755556 51.2 0 73.955556l-312.888888 312.888889 312.888888 312.888889z"
            fill="#727477"
            p-id="2990"
          />
        </svg>
      </div>
      <div class="box_bottom grade2">
        <div
          class="box_bottom_hzbh"
          :style="{ 'background-image': `url(${item.levelImg})` }"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="hzbh_img">
            <img :src="item.imageUrl" width="100%" height="100%" />
          </div>
          <div class="hzbh_bl">{{ item.oddsResult }}%</div>
          <div class="hzbh_name textover-f">{{ item.name }}</div>
          <div class="hzbh_money">
            <money class="money"></money>
            {{ item.usePrice }}
          </div>
        </div>
      </div>
      <div
        class="cerate_btn center"
        @click="
          handleclose();
          playAudio();
        "
      >
        关闭
      </div>
    </div>
  </div>
</template>

<script>
import { getDetail } from "@/api/index";

export default {
  props: ["boxDetail"],
  data() {
    return {
      show_box_detail: false,
      list: [],
    };
  },
  created() {
    window.addEventListener("popstate", () => {
      if (this.show_box_detail) {
        this.show_box_detail = false;
      }
    });
    // this.$bus.$on("show_box_detail", (data) => {
    //   this.show_box_detail = data.flag;
     
    // });
  },
  beforeDestroy() {
    // this.$bus.$off('show_box_detail')
  },
  computed: {},
  watch: {
    boxDetail: {
      handler(newValue) {
        if (newValue.flag) {
          const state = { title: "title", url: "#" };
          console.log("window.histroy", window);
          window.history.pushState(state, state.title, state.url);
          getDetail(newValue.boxId).then((res) => {
            console.log("simpleBoxDetail", res);
            this.list = res.data.data.boxOrnamentsList.sort((a, b) => b.usePrice - a.usePrice);
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    playAudio() {
      this.$store.commit("playAudio");
    },
    /* 关闭 */
    handleclose() {
      const element = this.$refs.slider;
      element.classList += " animate__fadeOutDown";
      setTimeout(() => {
        this.boxDetail.flag=false
        this.$router.back();
      }, 350);
    },
  },
};
</script>

<style lang="scss" scoped>
.box_bottom {
  width: 90%;
  margin: 0 auto;
  // display: flex;
  height: auto;
  max-height: 70vh;
  overflow-y: auto;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #25f484;
    border-radius: 5px;
  }

  @media (max-width: 550px) {
    &::-webkit-scrollbar {
      width: 2px;
    }
  }
  .box_bottom_hzbh {
    position: relative;
    margin-top: 4px;
    background-size: 100% 100%;
    width: 97%;
    height: 224px;
    background-color: #1f2023;
    border-radius: 14px 14px 14px 14px;
    transition: all 0.2s ease-in-out;

    opacity: 1;
    margin: 1%;
    &:hover {
      .hzbh_img {
        transition: all 0.2s ease-in-out;
        transform: translateY(-10px);
      }
    }
    @media (max-width: 550px) {
      height: 187px;
    }

    .hzbh_img {
      transition: all 0.2s ease-in-out;

      // height: 168px;
      width: 168px;
      height: 126px;
      margin: 0 auto;
      margin-top: 30px;
      @media (max-width: 550px) {
        margin-top: 28px;

        width: 120px;
        height: 90px;
      }
    }
    .hzbh_name {
      font-size: 19px;
      @media (max-width: 550px) {
        font-size: 13px;
      }
    }
    .hzbh_bl {
      position: absolute;
      right: 7px;
      top: 14px;
      font-weight: bold;
      @media (max-width: 550px) {
        font-size: 13px;
      }
    }
    .hzbh_money {
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      color: #25f484;
      margin-top: 12px;
      font-size: 21px;
      @media (max-width: 550px) {
        font-size: 15px;
      }
    }
  }
  .box_bottom_hydl {
    position: relative;
    margin-top: 4px;
    background-size: 100% 100%;
    width: 100%;
    height: 175px;
    background-color: #1f2023;
    border-radius: 14px 14px 14px 14px;
    transition: all 0.2s ease-in-out;
    opacity: 1;
    font-size: 13px;

    &:hover {
      .hydl_img {
        transition: all 0.2s ease-in-out;
        transform: translateY(-10px);
      }
      // background-color: red;
    }

    @media (max-width: 550px) {
      height: 125px;
      font-size: 9px;
    }
    .hydl_img {
      width: 80%;
      transition: all 0.2s ease-in-out;

      // height: 112px;
      margin: 0 auto;
      margin-top: 5%;
      @media (max-width: 550px) {
        width: 80px;
        height: 80px;
      }
    }
    .hydl_money {
      margin-top: 5%;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      color: #25f484;
      // font-size: 14px;
      .moneys {
        margin-right: 2px;
        width: 14px;
        height: 14px;
      }
    }
  }
}
.creat_bg {
  position: fixed;
  top: 0;
  left: 50%;
  // margin-left: -262.5px;
  width: 525px;
  transform: translateX(-50%);
  height: 100vh;
  z-index: 20;
  background: rgba(0, 0, 0, 0.85);
  padding: 2% 0;
  @media (max-width: 550px) {
    width: 100%;
  }
  .creat_close {
    width: 100%;
    text-align: right;
    position: relative;
    height: 25px;

    .icon {
      position: absolute;
      right: 10px;
      margin: 0;
      width: 22px;
      height: 22px;
      @media (max-width: 550px) {
        width: 16px;
        height: 16px;
      }
    }
  }
  .cerate_btn {
    width: 93.5%;
    height: 56px;
    margin: 0 auto;
    margin-top: 14px;
    background: linear-gradient(
      360deg,
      rgba(37, 244, 132, 0.2) 0%,
      rgba(37, 244, 132, 0.1) 100%
    );
    opacity: 1;
    border: 1px solid #25f484;
    font-size: 25px;
    @media (max-width: 550px) {
      font-size: 18px;
      height: 40px;
    }
  }
}
</style>
